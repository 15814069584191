var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sellgold",
      on: {
        click: function($event) {
          return _vm.isDo()
        }
      }
    },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "卖料",
          "safe-area-inset-top": "",
          fixed: "",
          "left-arrow": "",
          placeholder: "",
          border: false
        },
        on: { "click-left": _vm.onClickLeft }
      }),
      _c(
        "div",
        { staticClass: "rel" },
        [
          _c("center", { staticClass: "boxgoldprice" }, [
            _c("h2", [_vm._v(_vm._s(_vm.pricebox))]),
            _c("div", { staticClass: "pricebox" }, [
              _vm._v(_vm._s(_vm.initCity))
            ])
          ]),
          _c("div", { staticClass: "bottombox" }, [
            _c(
              "div",
              { staticClass: "userselect" },
              [
                _c("van-cell", {
                  attrs: {
                    title: "订单类型",
                    "is-link": "",
                    value: _vm.initCity,
                    size: "large"
                  },
                  on: { click: _vm.showPopup }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "业务类型",
                    value: _vm.businessName,
                    size: "large"
                  }
                }),
                _c("div", { staticClass: "title_box " }, [
                  _c(
                    "div",
                    { staticClass: "flex-between weightbox" },
                    [
                      _c("span", [_vm._v("重量")]),
                      _c("el-input", {
                        class: _vm.isinput ? "yellowfont" : "",
                        attrs: {
                          placeholder: "0",
                          type: "number",
                          maxlength: "6",
                          disabled: _vm.disabled
                        },
                        on: { input: _vm.handleInput2 },
                        model: {
                          value: _vm.weight,
                          callback: function($$v) {
                            _vm.weight = $$v
                          },
                          expression: "weight"
                        }
                      }),
                      _c("div", { staticClass: "unit" }, [_vm._v("克")])
                    ],
                    1
                  )
                ]),
                _vm.businessTypeID === "1"
                  ? _c(
                      "div",
                      { staticClass: "title_box " },
                      [
                        _c(
                          "van-radio-group",
                          {
                            attrs: { direction: "horizontal" },
                            on: { change: _vm.radioChange },
                            model: {
                              value: _vm.radio,
                              callback: function($$v) {
                                _vm.radio = $$v
                              },
                              expression: "radio"
                            }
                          },
                          [
                            _c(
                              "van-radio",
                              {
                                attrs: {
                                  name: "one",
                                  "icon-size": "16",
                                  "checked-color": "red"
                                }
                              },
                              [_vm._v("1000g")]
                            ),
                            _c(
                              "van-radio",
                              {
                                attrs: {
                                  name: "two",
                                  "icon-size": "16",
                                  "checked-color": "red"
                                }
                              },
                              [_vm._v("2000g")]
                            ),
                            _c(
                              "van-radio",
                              {
                                attrs: {
                                  name: "three",
                                  "icon-size": "16",
                                  "checked-color": "red"
                                }
                              },
                              [_vm._v("3000g")]
                            ),
                            _c(
                              "van-radio",
                              {
                                attrs: {
                                  name: "five",
                                  "icon-size": "16",
                                  "checked-color": "red"
                                }
                              },
                              [_vm._v("5000g")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.businessTypeID === "3"
                  ? _c(
                      "div",
                      { staticClass: "title_box " },
                      [
                        _c(
                          "van-radio-group",
                          {
                            attrs: { direction: "horizontal" },
                            on: { change: _vm.radioChangeMini },
                            model: {
                              value: _vm.radioMini,
                              callback: function($$v) {
                                _vm.radioMini = $$v
                              },
                              expression: "radioMini"
                            }
                          },
                          [
                            _c(
                              "van-radio",
                              {
                                attrs: {
                                  name: "one",
                                  "icon-size": "16",
                                  "checked-color": "red"
                                }
                              },
                              [_vm._v("100g")]
                            ),
                            _c(
                              "van-radio",
                              {
                                attrs: {
                                  name: "two",
                                  "icon-size": "16",
                                  "checked-color": "red"
                                }
                              },
                              [_vm._v("200g")]
                            ),
                            _c(
                              "van-radio",
                              {
                                attrs: {
                                  name: "three",
                                  "icon-size": "16",
                                  "checked-color": "red"
                                }
                              },
                              [_vm._v("300g")]
                            ),
                            _c(
                              "van-radio",
                              {
                                attrs: {
                                  name: "five",
                                  "icon-size": "16",
                                  "checked-color": "red"
                                }
                              },
                              [_vm._v("500g")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "title_box " }, [
                  _c("div", { staticClass: "flex-between weightbox" }, [
                    _c("span", [
                      _vm._v("定金 "),
                      _c("i", { staticClass: "dingJIn" }, [
                        _vm._v("(可用定金 "),
                        _c("span", { staticClass: "dingJinData" }, [
                          _vm._v(_vm._s(_vm.freeEarnestMoney))
                        ]),
                        _vm._v(" )")
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "countMumber",
                        style: { color: _vm.isinput ? "#242424" : "#D1D1D1" }
                      },
                      [
                        _c("span", { staticClass: "zuiDi" }, [
                          _vm._v("最低定金")
                        ]),
                        _vm._v(" " + _vm._s(_vm.countMumber))
                      ]
                    ),
                    _c("div", { staticClass: "unit" }, [_vm._v("元")])
                  ])
                ])
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "btnbox",
              style: { background: Number(_vm.weight) ? "#ff2d2e" : "#f7cdcd" },
              on: {
                click: function($event) {
                  return _vm.postData("showpop")
                }
              }
            },
            [_vm._v("立即卖料")]
          ),
          _c("div", { staticClass: "tipBox" }, [
            _vm._m(0),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.sellTips) } })
          ]),
          _c("van-action-sheet", {
            attrs: {
              actions: _vm.category,
              "cancel-text": "取消",
              "close-on-click-action": ""
            },
            on: { select: _vm.confirmCategory },
            model: {
              value: _vm.show,
              callback: function($$v) {
                _vm.show = $$v
              },
              expression: "show"
            }
          }),
          _c("van-action-sheet", {
            attrs: {
              actions: _vm.businessType,
              "cancel-text": "取消",
              "close-on-click-action": ""
            },
            on: { select: _vm.onSelect },
            model: {
              value: _vm.sheetShow,
              callback: function($$v) {
                _vm.sheetShow = $$v
              },
              expression: "sheetShow"
            }
          }),
          _c(
            "van-dialog",
            {
              model: {
                value: _vm.showorder,
                callback: function($$v) {
                  _vm.showorder = $$v
                },
                expression: "showorder"
              }
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("订单确认")]),
              _c("div", { staticClass: "confirmCenterbox_dialog" }, [
                _c("div", { staticClass: "flex orderInfo" }, [
                  _c("div", { staticClass: "orderCol" }, [_vm._v("订单类型")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.initCity) + "实物")])
                ]),
                _c("div", { staticClass: "flex orderInfo" }, [
                  _c("div", { staticClass: "orderCol" }, [_vm._v("订单类型")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.businessName))])
                ]),
                _c("div", { staticClass: "flex orderInfo" }, [
                  _c("div", { staticClass: "orderCol" }, [_vm._v("重量")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.weight) + "g")])
                ]),
                _c("div", { staticClass: "flex orderInfo" }, [
                  _c("div", { staticClass: "orderCol" }, [_vm._v("单价")]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticStyle: { color: "#FF4B71" } }, [
                      _vm._v(_vm._s(_vm.pricebox))
                    ]),
                    _vm._v(" 元/克")
                  ])
                ]),
                _c("div", { staticClass: "flex orderInfo" }, [
                  _c("div", { staticClass: "orderCol" }, [_vm._v("押金")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.countMumber) + "元")])
                ])
              ]),
              _c("div", { staticClass: "autograph" }, [
                _c("div", { staticClass: "autographText" }, [
                  _vm._v("手写签名")
                ]),
                _c("div", { staticClass: "autographBox" }, [
                  _vm.img
                    ? _c("img", { attrs: { src: _vm.img, alt: "" } })
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "flex popbtn" }, [
                _c(
                  "div",
                  {
                    staticClass: "gocer closebtn",
                    on: { click: _vm.closepop }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "gocer confirmbtn",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.postData("postDataf")
                      }
                    }
                  },
                  [_vm._v("确定")]
                )
              ]),
              _c(
                "div",
                { staticClass: "Loading" },
                [
                  _vm.Loading
                    ? _c("van-loading", { attrs: { size: "30" } })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "van-dialog",
            {
              staticClass: "ceringModel",
              model: {
                value: _vm.ceringModel,
                callback: function($$v) {
                  _vm.ceringModel = $$v
                },
                expression: "ceringModel"
              }
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("提示")]),
              _c("div", { staticClass: "centerbox_dialog" }, [
                _vm._v(
                  _vm._s(
                    _vm.businessTypeID === "1"
                      ? _vm.stopTrading
                      : _vm.stopMiniTrading
                  )
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "confirm",
                  on: {
                    click: function($event) {
                      _vm.ceringModel = false
                    }
                  }
                },
                [_vm._v("我知道了")]
              )
            ]
          ),
          _c(
            "van-dialog",
            {
              staticClass: "ceringModel",
              model: {
                value: _vm.depositModel,
                callback: function($$v) {
                  _vm.depositModel = $$v
                },
                expression: "depositModel"
              }
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("提示")]),
              _c(
                "div",
                {
                  staticClass: "centerbox_dialog",
                  staticStyle: { "border-bottom": "none" }
                },
                [_vm._v("当前押金不足，请先补交足够押金再进行定价")]
              ),
              _c("div", { staticClass: "flex popbtn" }, [
                _c(
                  "div",
                  {
                    staticClass: "gocer closebtn",
                    on: {
                      click: function($event) {
                        _vm.depositModel = false
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "gocer confirmbtn",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.goDeposit($event)
                      }
                    }
                  },
                  [_vm._v("确定")]
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v(" 温馨提示 "),
      _c("div", { staticClass: "leftLine" }),
      _c("div", { staticClass: "rightLine" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }